import { networks as safeNetworks } from '@safe-global/protocol-kit/dist/src/utils/eip-3770/config'

/**
 * A static shortName<->chainId dictionary
 * E.g.:
 *
 * {
 *   eth: '1',
 *   gor: '5',
 *   ...
 * }
 */
type Chains = Record<string, string>

const customNetworks = [{ shortName: 'haqq', chainId: 11235 }]

const allNetworks = [...safeNetworks, ...customNetworks]

const chains = allNetworks.reduce<Chains>((result, { shortName, chainId }) => {
  result[shortName] = chainId.toString()
  return result
}, {})

export default chains
